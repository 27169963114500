interface GrapQLlErrorException {
  stacktrace: string[];
}

interface GrapQLlErrorExtension {
  code: string;
  exception?: GrapQLlErrorException;
}

interface GraphQLErrorLocation {
  line: number;
  column: number;
}

interface GraphQLError {
  message: string;
  locations?: GraphQLErrorLocation[];
  extensions?: GrapQLlErrorExtension;
}

interface GrapQLlResponse<T = any> {
  errors?: GraphQLError[] | null;
  data: T;
}

export default function createGraphqlClient(baseUrl: string) {
  return async function graphqlClient<TVariables = any, TData = any>(
    query: string,
    variables: TVariables,
    accessToken?: string
  ): Promise<TData> {
    const response = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const payload: GrapQLlResponse<TData> = await response.json();

    if (payload?.errors?.length) {
      const errorMessage = payload.errors[0].message;

      throw new Error(errorMessage);
    }

    return payload.data;
  };
}
