import isObject from 'lodash/isObject';
export type Listener = (...args: any[]) => void;

export interface Events {
  [event: string]: Listener[];
}

class EventEmitter {
  private readonly events: Events = {};

  on(event: string, listener: Listener): () => void {
    if (!isObject(this.events[event])) {
      this.events[event] = [];
    }

    this.events[event].push(listener);
    return () => this.off(event, listener);
  }

  off = (event: string, listener: Listener): void => {
    if (!isObject(this.events[event])) {
      return;
    }

    const idx: number = this.events[event].indexOf(listener);
    if (idx > -1) {
      this.events[event].splice(idx, 1);
    }
  };

  emit = (event: string, ...args: any[]): void => {
    if (!isObject(this.events[event])) {
      return;
    }

    [...this.events[event]].forEach(listener => listener.apply(this, args));
  };

  once = (event: string, listener: Listener): (() => void) => {
    const remove: () => void = this.on(event, (...args: any[]) => {
      remove();
      listener.apply(this, args);
    });

    return remove;
  };
}

const events = new EventEmitter();

export default events;
