import jtwDecode from 'jwt-decode';
import localForage from 'localforage';
import createApiService from './api-service';
import {
  ID_APP_BASE_URL,
  ACCESS_TOKEN_KEY,
  CURRENT_USER_KEY,
  REFRESH_TOKEN_KEY,
  STORAGE_KEY,
  DEVELOPMENT_ID_APP_BASE_URL,
} from './constants';
import events, { Listener } from './events';
import {
  ApiAuthorizationHeaders,
  Application,
  AuthOptions,
  AuthPayload,
  Credentials,
  User,
  UserInput,
  AuthClient,
} from './types';

const store = localForage.createInstance({
  name: STORAGE_KEY,
});

async function persitAuthorization(access_token: string, refresh_token: string) {
  return Promise.all([store.setItem(ACCESS_TOKEN_KEY, access_token), store.setItem(REFRESH_TOKEN_KEY, refresh_token)]);
}

async function cleanPersistedData() {
  await Promise.all([
    store.removeItem(ACCESS_TOKEN_KEY),
    store.removeItem(REFRESH_TOKEN_KEY),
    store.removeItem(CURRENT_USER_KEY),
  ]);
}

const defaultOptions: AuthOptions = {
  developmentUrl: DEVELOPMENT_ID_APP_BASE_URL,
  productionUrl: ID_APP_BASE_URL,
};

export default function createClient(options: AuthOptions = defaultOptions): AuthClient {
  const baseUrl =
    (__DEV__ ? options.developmentUrl : options.productionUrl) ??
    (__DEV__ ? DEVELOPMENT_ID_APP_BASE_URL : ID_APP_BASE_URL);

  const apiService = createApiService({
    baseUrl: `${baseUrl.replace(/(\/+)$/, '')}/api/graphql`,
  });

  async function getValidToken(tokenName: string, safeTime: number): Promise<string | undefined> {
    const token = await store.getItem<string>(tokenName);

    if (!token) {
      return;
    }

    try {
      const tokenPayload: any = jtwDecode(token);

      if (tokenPayload.exp > Date.now() / 1000 + safeTime) {
        return token;
      }

      return;
    } catch (e) {
      await Promise.all([store.removeItem(tokenName), store.removeItem(CURRENT_USER_KEY)]);

      const accessToken = await store.getItem<string>(ACCESS_TOKEN_KEY);

      if (tokenName === REFRESH_TOKEN_KEY && !accessToken) {
        fireEvent('logout');
      }

      return;
    }
  }

  const getAccessToken = () => getValidToken(ACCESS_TOKEN_KEY, 60);
  const getRefreshToken = () => getValidToken(REFRESH_TOKEN_KEY, 60);

  async function fireEvent(event: string, ...args: any) {
    events.emit(event, ...args);

    if (event === 'logout') {
      events.emit('authStateChanged');
      return;
    }

    const user = await currentUser();

    events.emit('authStateChanged', user);
  }

  async function signInWithCustomToken(accessToken: string, refreshToken: string): Promise<boolean> {
    await persitAuthorization(accessToken, refreshToken);

    fireEvent('app_authorize');

    if (await isLoggedIn()) {
      fireEvent('login');
      return true;
    }

    return false;
  }

  async function signInWithCredential(username: string, password: string): Promise<AuthPayload> {
    const authorization = await apiService.login(username, password);

    await persitAuthorization(authorization?.access_token, authorization?.refresh_token);

    fireEvent('login');

    return authorization;
  }

  async function requestPasswordRecovery(email: string): Promise<boolean> {
    return await apiService.requestPasswordRecovery(email);
  }

  async function resetPassword(token: string, newPassword: string): Promise<boolean> {
    return await apiService.resetPassword(token, newPassword);
  }

  function persistCurrentUser(user: User) {
    return store.setItem(CURRENT_USER_KEY, user);
  }

  async function reloadCurrentUserData(): Promise<User | null> {
    if (!(await isLoggedIn())) {
      await store.removeItem(CURRENT_USER_KEY);
      return null;
    }

    try {
      const accessToken = await getValidAccessToken();

      if (!accessToken) {
        return null;
      }

      const currentUser = await apiService.currentUser(accessToken);
      await persistCurrentUser(currentUser);

      return Object.freeze(currentUser);
    } catch (e) {
      await store.removeItem(CURRENT_USER_KEY);
      return null;
    }
  }

  async function currentUser(): Promise<User | null> {
    if (!(await isLoggedIn())) {
      return null;
    }

    try {
      const currentUser = await store.getItem<User>(CURRENT_USER_KEY);

      if (currentUser) {
        return Object.freeze(currentUser);
      }
    } catch (e) {
      await store.removeItem(CURRENT_USER_KEY);
    }

    return reloadCurrentUserData();
  }

  async function signOut(): Promise<void> {
    try {
      const accessToken = await getAccessToken();
      await apiService.logout(accessToken);
    } catch (e) {
    } finally {
      fireEvent('logout');
      await cleanPersistedData();
    }
  }

  async function signInAuthorizedApplication(appId: string): Promise<Application | undefined> {
    try {
      const accessToken = await getValidAccessToken();

      if (!accessToken) {
        return;
      }

      return await apiService.authorize(appId, accessToken);
    } catch (e) {
      throw e;
    } finally {
      fireEvent('app_authorize');
    }
  }

  async function updateCurrentUser(payload: UserInput): Promise<User> {
    const user = await currentUser();

    const accessToken = await getValidAccessToken();

    try {
      const updatedUser = await apiService.updateUser(user?.id!, payload, accessToken);
      persistCurrentUser(updatedUser);
      return updatedUser;
    } catch (e) {
      throw e;
    } finally {
      fireEvent('user_updated');
    }
  }

  async function confirmEmail(token: string): Promise<boolean> {
    try {
      const result = await apiService.confirmEmail(token);
      return result.affected_rows > 0;
    } catch (e) {
      throw e;
    } finally {
      fireEvent('confirm_email');
    }
  }

  async function changePassword(newPassword: string): Promise<boolean> {
    const user = await currentUser();

    const accessToken = await getValidAccessToken();

    try {
      await apiService.changePassword(user?.id!, newPassword, accessToken);
      return true;
    } catch (e) {
      throw e;
    } finally {
      fireEvent('change_password');
    }
  }

  async function fetchOrganizationApps(): Promise<Application[]> {
    const accessToken = await getValidAccessToken();

    return apiService.fetchOrganizationApps(accessToken);
  }

  let refreshingToken: undefined | Promise<Credentials>;

  async function refresh(): Promise<Credentials> {
    if (refreshingToken) {
      return refreshingToken;
    }

    refreshingToken = new Promise(async (resolve, reject) => {
      try {
        const refreshToken = await getRefreshToken();
        const authorization = await apiService.refresh(refreshToken);

        if (!authorization?.access_token || !authorization?.refresh_token) {
          throw Error('Invalid authorization response');
        }

        await persitAuthorization(authorization.access_token, authorization.refresh_token);

        fireEvent('refresh');
        resolve(authorization);
      } catch (e) {
        if (e.message === 'Session invalid or expired' || e.message === 'Unable to find the user session') {
          await signOut();
        }
        reject(e);
      } finally {
        refreshingToken = void 0;
      }
    });

    return refreshingToken;
  }

  async function isLoggedIn(): Promise<boolean> {
    const [accessToken, refreshToken] = await Promise.all([getAccessToken(), getRefreshToken()]);

    if (!accessToken && !refreshToken) {
      return false;
    }

    return true;
  }

  async function getValidAccessToken(): Promise<string | undefined> {
    const [accessToken, refreshToken] = await Promise.all([getAccessToken(), getRefreshToken()]);

    if (accessToken) {
      return accessToken;
    }

    if (!refreshToken) {
      return;
    }

    try {
      await refresh();
      return getAccessToken();
    } catch (e) {
      return;
    }
  }

  async function getHttpHeaders(): Promise<ApiAuthorizationHeaders> {
    if (!(await isLoggedIn())) {
      return {};
    }

    const accessToken = await getValidAccessToken();

    if (!accessToken) {
      return {};
    }

    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  async function getCredentials(): Promise<Credentials | undefined> {
    if (!(await isLoggedIn())) {
      return;
    }

    const [access_token, refresh_token] = await Promise.all([getAccessToken(), getRefreshToken()]);

    return {
      access_token: access_token!,
      refresh_token: refresh_token!,
    };
  }

  const onAuthStateChanged = (listener: Listener) => events.on('authStateChanged', listener);
  const onLogin = (listener: Listener) => events.on('login', listener);
  const onLogout = (listener: Listener) => events.on('logout', listener);
  const onRefresh = (listener: Listener) => events.on('refresh', listener);
  const onUserUpdate = (listener: Listener) => events.on('user_updated', listener);
  const onConfirmEmail = (listener: Listener) => events.on('confirm_email', listener);

  return {
    baseUrl,

    // Helpers
    // getAccessToken,
    // getRefreshToken,
    // getValidAccessToken,
    getCredentials,
    getHttpHeaders,

    // SignUp
    confirmEmail,

    // SignIn
    signInAuthorizedApplication,
    signInWithCredential,
    signInWithCustomToken,

    // SignOut
    signOut,
    cleanPersistedData,

    // Logged User
    isLoggedIn,
    fetchOrganizationApps,
    reloadCurrentUserData,
    updateCurrentUser,
    changePassword,
    currentUser,

    // Events
    onAuthStateChanged,
    onConfirmEmail,
    onUserUpdate,
    onLogin,
    onLogout,
    onRefresh,
    requestPasswordRecovery,
    resetPassword,
  };
}
