import createGraphqlClient from './create-graphql-client';
import { AffectedRows, Application, AuthPayload, Credentials, User, ApiOptions } from './types';

export default function createInstance(options: ApiOptions) {
  const graphqlClient = createGraphqlClient(options.baseUrl);

  type CurrentUserResponse = {
    me: User;
  };

  type CurrentUserVariables = {};

  async function currentUser(accessToken: string): Promise<User> {
    const response = await graphqlClient<CurrentUserVariables, CurrentUserResponse>(
      `
  query {
    me {
      id
      organization_id
      organization {
        id
        name
        cnpj
        organization_apps {
          id
          created_at
          updated_at
          app {
            id
            name
            slug_name
            url
          }
        }
      }
      email
      name
      cpf
      role
      settings
      email_verified_at
      created_at
      updated_at
    }
  }
  `,
      {},
      accessToken
    );

    return response.me;
  }

  type LoginResponse = {
    login: AuthPayload;
  };

  type LoginVariables = {
    username: string;
    password: string;
  };

  async function login(username: string, password: string): Promise<AuthPayload> {
    const response = await graphqlClient<LoginVariables, LoginResponse>(
      `
  mutation ($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user_id
      organization_id
      access_token
      refresh_token
    }
  }
  `,
      { username, password }
    );

    return response.login;
  }

  type RequestPasswordRecoveryResponse = {
    request_password_recovery: boolean;
  };

  type RequestPasswordRecoveryVariables = {
    email: string;
  };

  async function requestPasswordRecovery(email: string): Promise<boolean> {
    const response = await graphqlClient<RequestPasswordRecoveryVariables, RequestPasswordRecoveryResponse>(
      `
    mutation ($email: String!) {
      request_password_recovery(email: $email)
    }
    `,
      { email }
    );

    return response.request_password_recovery;
  }

  type ResetPasswordResponse = {
    reset_password: boolean;
  };

  type ResetPasswordVariables = {
    recoveryToken: string;
    newPassword: string;
  };

  async function resetPassword(recoveryToken: string, newPassword: string): Promise<boolean> {
    const response = await graphqlClient<ResetPasswordVariables, ResetPasswordResponse>(
      `
    mutation ($recoveryToken: String!, $newPassword: String!) {
      reset_password(recovery_token: $recoveryToken, new_password: $newPassword)
    }
    `,
      { recoveryToken, newPassword }
    );

    return response.reset_password;
  }

  type LogoutResponse = {
    logout: AffectedRows;
  };

  type LogoutVariables = {};

  async function logout(accessToken?: string): Promise<AffectedRows> {
    const defaultResponse = Object.freeze({
      affected_rows: 0,
    });

    if (!accessToken) {
      return defaultResponse;
    }

    try {
      const response = await graphqlClient<LogoutVariables, LogoutResponse>(
        `
      mutation {
        logout {
          affected_rows
        }
      }
    `,
        {},
        accessToken
      );

      return response.logout;
    } catch (e) {
      return defaultResponse;
    }
  }

  type RefreshResponse = {
    refresh_token: Credentials;
  };

  type RefreshVariables = {
    refresh_token: string;
  };

  async function refresh(refreshToken?: string): Promise<Credentials> {
    if (!refreshToken) {
      throw new Error('User is not logged in');
    }

    const response = await graphqlClient<RefreshVariables, RefreshResponse>(
      `
    mutation ($refresh_token: String!) {
      refresh_token(refresh_token: $refresh_token) {
        access_token
        refresh_token
      }
    }
  `,
      {
        refresh_token: refreshToken,
      }
    );

    return response.refresh_token;
  }

  type ConfirmEmailResponse = {
    confirm_email: AffectedRows;
  };

  type ConfirmEmailVariables = {
    token: string;
  };

  async function confirmEmail(token?: string): Promise<AffectedRows> {
    if (!token) {
      throw new Error('Invalid email token');
    }

    const response = await graphqlClient<ConfirmEmailVariables, ConfirmEmailResponse>(
      `
    mutation ($token: String!) {
      confirm_email(token: $token) {
        affected_rows
      }
    }
  `,
      {
        token,
      }
    );

    return response.confirm_email;
  }

  type AuthorizeResponse = {
    authorize: Application;
  };

  type AuthorizeVariables = {
    slug_name: string;
  };

  async function authorize(slugName: string, accessToken?: string): Promise<Application> {
    if (!slugName) {
      throw new Error('Application id has not provided');
    }

    if (!accessToken) {
      throw new Error('User is not logged in');
    }

    const response = await graphqlClient<AuthorizeVariables, AuthorizeResponse>(
      `
    mutation ($slug_name: String!) {
      authorize(slug_name: $slug_name) {
        id
        name
        url
        slug_name
      }
    }
  `,
      {
        slug_name: slugName,
      },
      accessToken
    );

    return response.authorize;
  }

  type UserInput = {
    name?: string;
    cpf?: string;
    email?: string;
  };

  type UpdateUserResponse = {
    update_user: User;
  };

  type UpdateUserVariables = {
    user_id: string;
    payload: UserInput;
  };

  async function updateUser(userId: string, payload: UserInput, accessToken?: string): Promise<User> {
    if (!userId) {
      throw new Error('User id has not provided');
    }

    if (!accessToken) {
      throw new Error('User is not logged in');
    }

    const response = await graphqlClient<UpdateUserVariables, UpdateUserResponse>(
      `
    mutation ($user_id: ID!, $payload: UserInput! ) {
      update_user(user_id: $user_id, payload: $payload) {
        id
        organization_id
        email
        name
        cpf
        is_admin
        email_verified_at
        created_at
        updated_at
      }
    }
  `,
      {
        user_id: userId,
        payload,
      },
      accessToken
    );

    return response.update_user;
  }

  type ChangePasswordResponse = {
    change_password: AffectedRows;
  };

  type ChangePasswordVariables = {
    user_id: string;
    new_password: string;
  };

  async function changePassword(userId: string, newPassword: string, accessToken?: string): Promise<AffectedRows> {
    if (!userId) {
      throw new Error('User id has not provided');
    }

    if (!accessToken) {
      throw new Error('User is not logged in');
    }

    const response = await graphqlClient<ChangePasswordVariables, ChangePasswordResponse>(
      `
    mutation ($user_id: ID!, $new_password: String!) {
      change_password(user_id: $user_id, new_password: $new_password) {
        affected_rows
      }
    }
  `,
      {
        user_id: userId,
        new_password: newPassword,
      },
      accessToken
    );

    return response.change_password;
  }

  async function fetchOrganizationApps(accessToken?: string): Promise<Application[]> {
    if (!accessToken) {
      throw new Error('User is not logged in');
    }

    const user = await currentUser(accessToken);

    return user?.organization?.organization_apps?.map(({ app }) => app) ?? [];
  }

  return {
    currentUser,
    login,
    requestPasswordRecovery,
    resetPassword,
    logout,
    refresh,
    confirmEmail,
    authorize,
    updateUser,
    changePassword,
    fetchOrganizationApps,
  };
}
